import {
  FC,
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { Search, Menu, X, ArrowLeft } from "react-feather";
import { Navbar, Button } from "../../components";
import { menuData } from "../../shared/data";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggleSidebar, toggleBody } from "../../redux/slices/ui";
import NavSearch from "../../layouts/header/nav-search/NavSearch";
import ProfileDropdown from "../../../src/components/src/header/profile-dropdown/index";
import Logo from "../logo/Logo";
import MessageDropdown from "../header/birthday-dropdown/birthday-dropdown";

import IconButton from "@mui/material/IconButton";
import {
  StyledHeader,
  StyledLogo,
  StyledNavbarWrap,
  StyledNavbarMenu,
  StyleNavbarRight,
  StyledNavbarElement,
  StyledNavbarHeader,
  StyledNavbarBody,
  StyledNavbarTitle,
  StyledMenuBtn,
  StyledSidebarBtn,
} from "./style";

import { useTheme, Tooltip } from "@mui/material";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import { ThemeContext } from "../../context/ThemeContextProvider";

interface IProps {
  hasSidebar?: boolean;
  sidebarLayout?: 1 | 2;
}

export const Nav: FC<IProps> = ({ hasSidebar, sidebarLayout }) => {
  const dispatch = useAppDispatch();
  const { sidebar, isBody } = useAppSelector((state) => state.ui);
  const [searchOpen, setSearchOpen] = useState(false);
  const searchHandler = useCallback(() => {
    setSearchOpen((prev) => !prev);
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarHandler = useCallback(
    (_, isOpen?: "open") => {
      dispatch(toggleSidebar({ isOpen }));
    },
    [dispatch]
  );

  const theme = useTheme();
  const { switchColorMode, darkModeActive } = useContext(ThemeContext);
  const activateName = useMemo(
    () => (theme.palette.mode === "dark" ? "Light" : "Dark"),
    [theme]
  );

  const bodyHandler = useCallback(() => {
    dispatch(toggleBody());
    dispatch(toggleSidebar({ isOpen: "open" }));
  }, [dispatch]);

  const menuHandler = useCallback(() => {
    setMenuOpen((prev) => !prev);
    if (menuOpen) {
      sidebarHandler(undefined, "open");
    }
  }, [menuOpen, sidebarHandler]);

  useEffect(() => {
    return () => {
      sidebarHandler(undefined, "open");
      bodyHandler();
    };
  }, [sidebarHandler, bodyHandler]);

  return (
    <>
      <StyledHeader darkModeActive={darkModeActive}>
        {hasSidebar && sidebarLayout === 1 && (
          <>
            <StyledMenuBtn
              variant="texted"
              onClick={menuHandler}
              $hasSidebar={hasSidebar}
              $sidebarOpen={sidebar}
              $bodyOpen={isBody}
              className="menu-btn"
              darkModeActive={darkModeActive}
            >
              <Menu size={20} strokeWidth="2.5px" />
            </StyledMenuBtn>
            <StyledSidebarBtn
              variant="texted"
              onClick={!isBody ? sidebarHandler : bodyHandler}
              $sidebarOpen={sidebar}
              $bodyOpen={isBody}
              className="sidebar-btn"
              darkModeActive={darkModeActive}
            >
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn>
          </>
        )}
        {hasSidebar && sidebarLayout === 2 && (
          <>
            <StyledMenuBtn
              variant="texted"
              onClick={menuHandler}
              $hasSidebar={hasSidebar}
              $sidebarOpen={!sidebar}
              darkModeActive={darkModeActive}
            >
              <Menu size={20} strokeWidth="2.5px" />
            </StyledMenuBtn>
            <StyledSidebarBtn
              variant="texted"
              onClick={sidebarHandler}
              $sidebarOpen={!sidebar}
              darkModeActive={darkModeActive}
            >
              <ArrowLeft size={20} strokeWidth="2.5px" />
            </StyledSidebarBtn>
          </>
        )}
        {!hasSidebar && (
          <StyledMenuBtn
            variant="texted"
            onClick={menuHandler}
            $hasSidebar={hasSidebar}
            $sidebarOpen={!sidebar}
            darkModeActive={darkModeActive}
          >
            <Menu size={20} strokeWidth="2.5px" />
          </StyledMenuBtn>
        )}
        <StyledLogo>
          <Logo />
        </StyledLogo>
        <StyledNavbarWrap $isOpen={menuOpen} onClick={menuHandler}>
          <StyledNavbarMenu
            $isOpen={menuOpen}
            onClick={(e) => e.stopPropagation()}
            darkModeActive={darkModeActive}
          >
            <StyledNavbarHeader darkModeActive={darkModeActive}>
              <Button variant="texted" onClick={menuHandler}>
                <X color="#7987a1" width={20} strokeWidth="2.5px" />
              </Button>
            </StyledNavbarHeader>
            <StyledNavbarBody>
              <StyledNavbarTitle>MAIN NAVIGATION</StyledNavbarTitle>
              <Navbar menus={menuData} />
            </StyledNavbarBody>
          </StyledNavbarMenu>
        </StyledNavbarWrap>
        <StyleNavbarRight>
          <StyledNavbarElement
            mr={["8px", "15px"]}
            darkModeActive={darkModeActive}
          >
            <Tooltip title={`Activate ${activateName} Mode`}>
              <IconButton
                onClick={switchColorMode}
                sx={{
                  p: 1,
                  border: `1px ${theme.palette.text.disabled} solid`,
                }}
                size="large"
                color="inherit"
              >
                {theme.palette.mode === "dark" ? (
                  <LightModeOutlined
                    sx={{ color: darkModeActive ? "#cdd4e0" : "#001737" }}
                  />
                ) : (
                  <DarkModeOutlined
                    sx={{ color: darkModeActive ? "#cdd4e0" : "#001737" }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </StyledNavbarElement>

          <StyledNavbarElement darkModeActive={darkModeActive}>
            <Button
              variant="texted"
              onClick={searchHandler}
              className="search-btn"
            >
              <Search className="header-icon" />
            </Button>
          </StyledNavbarElement>
          <StyledNavbarElement
            ml={["8px", "15px"]}
            darkModeActive={darkModeActive}
          >
            <MessageDropdown />
          </StyledNavbarElement>

          <StyledNavbarElement
            ml={["15px", "20px", "30px"]}
            darkModeActive={darkModeActive}
          >
            <ProfileDropdown />
          </StyledNavbarElement>
        </StyleNavbarRight>
      </StyledHeader>
      <NavSearch isOpen={searchOpen} onClose={searchHandler} />
    </>
  );
};

Nav.defaultProps = {
  sidebarLayout: 1,
};

export default Nav;
